import { useForm, getInputProps, getFormProps } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import type { ActionFunctionArgs, MetaFunction } from '@remix-run/node';
import {
  Form,
  json,
  Link,
  redirect,
  useActionData,
  useNavigation
} from '@remix-run/react';
import { z } from 'zod';

import whiteStackedLogoSrc from '~/assets/logo-stacked-white.png';
import { Field } from '~/components/form-fields';
import { Button } from '~/components/ui/button';
import { Label } from '~/components/ui/label';
import { ServerHttpError } from '~/lib/http.server';
import { resendVerificationEmail } from '~/lib/session.server';

export const meta: MetaFunction = () => {
  return [{ title: 'Sublynk' }];
};

const schema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email()
    .transform((value) => value.toLowerCase().trim()),
  password: z
    .string({ required_error: 'Password is required' })
    .min(4, 'Password must be at least 4 characters')
});

export async function action({ request, context }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = await parseWithZod(formData, { schema });
  if (submission.status !== 'success') {
    return json(submission.reply(), { status: 400 });
  }

  try {
    const cookieHeader = await context.authService.login({
      email: submission.value.email,
      password: submission.value.password
    });
    return redirect('/dashboard', {
      headers: {
        'Set-Cookie': cookieHeader
      }
    });
  } catch (err) {
    // TODO: This particular error is not recognized as an instanceof ServerHttpError.
    // This is the only one that broke after the auth session refactor.
    const error = err as ServerHttpError;
    if (error.status === 400) {
      const apiRes = error.data as {
        nonFieldErrors?: string[];
        email?: string[];
        password?: string[];
      };

      if (apiRes.nonFieldErrors?.includes('E-mail is not verified.')) {
        await resendVerificationEmail(submission.value.email);
        const searchParams = new URLSearchParams({
          email: submission.value.email
        });
        throw redirect(`/confirm-email?${searchParams.toString()}`);
      }

      if (
        apiRes.nonFieldErrors?.includes(
          'Unable to log in with provided credentials.'
        )
      ) {
        return json(
          submission.reply({
            fieldErrors: {
              email: ['Invalid email or password'],
              password: ['Invalid email or password']
            }
          }),
          { status: 400 }
        );
      }

      return json(
        submission.reply({
          fieldErrors: apiRes
        }),
        { status: 400 }
      );
    }

    context.showToast({
      type: 'error',
      title: 'Something went wrong'
    });
    return json(submission.reply(), { status: 500 });
  }
}

export default function Index() {
  return (
    <div className="h-full w-full lg:grid lg:grid-cols-[3fr_2fr]">
      <div className="container flex h-full items-center justify-center py-12">
        <div className="mx-auto grid w-full max-w-[350px] gap-8">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-muted-foreground">
              Enter your email address and password
            </p>
          </div>

          <LoginForm />
        </div>
      </div>

      <div className="hidden items-center justify-center bg-burnt-sienna bg-gradient-to-br from-burnt-sienna-500 to-burnt-sienna-600 lg:flex">
        <img
          src={whiteStackedLogoSrc}
          alt="Sublynk"
          className="mx-auto h-20 w-auto opacity-25"
        />
      </div>
    </div>
  );
}

function LoginForm() {
  const navigation = useNavigation();
  const lastResult = useActionData<typeof action>();
  const [form, fields] = useForm<z.input<typeof schema>>({
    lastResult
  });

  return (
    <Form className="grid gap-6" method="post" {...getFormProps(form)}>
      <Field
        label="Email"
        inputProps={{
          ...getInputProps(fields.email, { type: 'email' }),
          autoComplete: 'email'
        }}
        errors={fields.email.errors}
      />

      <Field
        renderLabel={(labelProps) => (
          <div className="relative flex items-center">
            <Label {...labelProps}>Password</Label>
            <Link to="/forgot-password" className="link ml-auto text-xs">
              Forgot your password?
            </Link>
          </div>
        )}
        inputProps={{
          ...getInputProps(fields.password, { type: 'password' }),
          autoComplete: 'current-password'
        }}
        errors={fields.password.errors}
      />

      <Button
        type="submit"
        className="w-full"
        isLoading={navigation.state !== 'idle'}
      >
        Login
      </Button>

      <p className="mt-4 text-center text-sm">
        Don't have an account?{' '}
        <Link to="/create-account" className="link">
          Sign up
        </Link>
      </p>
    </Form>
  );
}
